import { format, parseISO } from "date-fns";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import TICK_ORANGE from "../../img/pages/live-demo/circle_checked.svg";
import {
  Button,
  Column,
  FormattedText,
  Grid,
  Gutter,
  HelmetSeo,
  Layout,
  Line,
  MaxWidth,
  Modal,
  PageMaxWidth,
  Section,
  SectionCustomerLogos,
  Text,
} from "../components";
import { FormattedRichText } from "../components/legacy";
import LiveDemoForm from "../components/live-demo/LiveDemoForm";
import { Color, Dimen } from "../constants";
import { fluidImage } from "../prismic-tools";

type Props = {
  data: GatsbyTypes.MonthlyDemoPageQuery;
};

export const query = graphql`
  query MonthlyDemoPage {
    prismicMonthlyDemoPage {
      data {
        hero_title
        hero_description {
          raw
        }
        hero_image {
          dimensions {
            width
            height
          }
          url
        }
        demos_heading
        demos {
          demo_title
          demo_subtitle
          demo_date
          demo_time
          demo_zoom_webinar_link
        }
        why_section_title
        why_bullets {
          why_bullet_title
          why_bullet_description {
            raw
          }
        }
        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function MonthlyDemoPage({ data }: Props) {
  const page = data.prismicMonthlyDemoPage?.data;
  if (!page) throw new Error("page missing");

  const [demoModal, setDemoModal] = React.useState<boolean>(false);

  const demoSessions: string[] = [];
  page.demos?.forEach((demo) => {
    if (demo?.demo_date) {
      demoSessions.push(
        `${demo.demo_title} at 4pm BST | ${format(
          parseISO(demo.demo_date),
          "MMMM do"
        )}`
      );
    }
  });

  return (
    <Layout>
      <HelmetSeo {...page} />

      <Modal visible={demoModal} onDismiss={() => setDemoModal(false)}>
        <LiveDemoForm
          demoSessions={demoSessions.map((x) => ({ label: x, value: x }))}
        />
      </Modal>

      <Section>
        <PageMaxWidth>
          <Gutter size={3} />
          <Grid reverseColumns>
            <Column size={6}>
              {page.demos?.map(
                (demo, i) =>
                  demo && (
                    <StyledCardBox key={i}>
                      <StyledCard>
                        <div className="first-row">
                          <Text typeface="h4" as="h2" bold>
                            {demo.demo_title}
                          </Text>
                          <Text bold>{demo.demo_subtitle}</Text>
                        </div>
                        <Gutter size={0.5} />
                        <div className="second-row">
                          <span>
                            <Text
                              color="secondaryOrange1"
                              as="p"
                              typeface="caption"
                            >
                              {demo.demo_date &&
                                format(
                                  parseISO(demo.demo_date),
                                  "EEEE do MMMM Y"
                                )}
                            </Text>
                            <Text
                              color="secondaryOrange1"
                              as="p"
                              typeface="caption"
                            >
                              {demo.demo_time}
                            </Text>
                          </span>
                          <Gutter size={0.25} />
                          <div>
                            <Button
                              colorTheme="orange"
                              label="Sign up"
                              onClick={() => window.open(demo.demo_zoom_webinar_link, "_blank")}
                            />
                          </div>
                        </div>
                      </StyledCard>
                    </StyledCardBox>
                  )
              )}
            </Column>
            <Column size={1} />
            <Column size={5}>
              <Text typeface="h1" as="h1" bold>
                {page.hero_title}
              </Text>
              <Gutter />
              <FormattedRichText
                render={page.hero_description?.raw}
                fontSize={22.5}
              />
              <Gutter size={4} />
            </Column>
          </Grid>
          <Gutter size={3} />
        </PageMaxWidth>
      </Section>

      <Section>
        <PageMaxWidth>
          <Grid>
            <Column size={6}>
              <Text typeface="h3" bold>
                {page.why_section_title}
              </Text>
            </Column>

            <Column size={6}>
              {page.why_bullets?.map(
                (bullet, i) =>
                  bullet && (
                    <React.Fragment key={i}>
                      <StyledTickList>
                        <img src={TICK_ORANGE} alt="" />
                        <div>
                          <Text color="secondaryOrange1">
                            {bullet.why_bullet_title}
                          </Text>
                          <FormattedText>
                            <RichText
                              render={bullet.why_bullet_description?.raw}
                            />
                          </FormattedText>
                        </div>
                      </StyledTickList>
                      <Gutter size={0.5} />
                    </React.Fragment>
                  )
              )}
            </Column>
          </Grid>
        </PageMaxWidth>
      </Section>
      <SectionCustomerLogos />
    </Layout>
  );
}

const StyledCard = styled.div`
  .first-row {
    display: flex;
    flex-direction: column;
  }

  .second-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    .first-row {
      align-items: center;
      text-align: center;
    }

    .second-row {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
`;

const StyledCardBox = styled.div`
  background-color: ${Color.light1};
  border: 2px solid #f1f1f1;
  box-shadow: 0px 8px 40px rgba(19, 19, 31, 0.05);
  border-radius: 24px;
  padding: 30px;
  min-height: 180px;
  margin-bottom: 20px;
`;

const StyledTickList = styled.div`
  display: flex;
  align-items: flex-start;

  img {
    margin-top: 3px;
    padding-right: 15px;
  }

  div {
    flex: 1;
  }
`;
