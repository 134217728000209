import React from "react";
import { useForm } from "react-typed-form";
import { FieldSelect, FieldText, SubmitButton } from "../../form";
import Column from "../Column";
import Grid from "../Grid";
import Gutter from "../Gutter";
import HorizontalAlign from "../HorizontalAlign";
import Text from "../Text";

type Props = {
  demoSessions: {
    value: string;
    label: string;
  }[];
};

export default function LiveDemoForm({ demoSessions }: Props) {
  const [success, setSuccess] = React.useState<boolean>(false);

  return (
    <iframe src="https://go.shipamax.com/l/952713/2022-01-21/wp5" width="100%" height="650" frameBorder="0" allowTransparency={true} style={{ border: 0 }}></iframe>
  );
}
